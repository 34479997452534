import React from 'react';
import moonslogo from '../../assets/images/moons_logo.svg';
import styles from './styles.module.css';

const Header = () => (
  <div className={styles.header}>
    <img className={styles.logo} src={moonslogo} alt="moons" width="70" height="16" />
  </div>
);

export default Header;
