/* eslint-disable */
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Home from './pages/home';
import './styles/fonts.module.css';
import './styles/global.module.css';
import {  sendCustomer } from './utils/Middleware'

const container = document.getElementById('root');
const root = createRoot(container);

const customerId = new URLSearchParams(window.location.search).get('customerId');

sendCustomer('MX-checkout-card_holder',customerId,"Card_required", false);

root.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>
);
