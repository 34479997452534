/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import { Button } from '@mymoons/ui-library';
import styles from './AlertModal.module.css';

/**
 * Component for modals
 * @param {object} data All the data information to make the modal
 * @param {function} modal Function (useState) to close and open modal
 * @param {boolean} discardOption Variable to change the construction of the secondary button
 * @returns Modal
 */

const AlertModal = ({ publicKey }) => {
  const data = {
    title: 'Tiempo agotado',
    description:'Debido al alto volumen de solicitudes, no podemos apartar tu seleccíon por mucho tiempo. Es necesario que selecciones nuevamente la fecha y la hora de tu cita.',
    button: 'Selecciona fecha y hora',
    imgAlert: `${process.env.REACT_APP_ASSETS_URL}/images/timer.webp`,
    urlRedirect: `${process.env.REACT_APP_APPOINTMENT_PLATFORM}/${publicKey}/?Journey_Variant=card_holder`,
    discardLink : false,
  };

  const $body = document.querySelector('body');
  const $html = document.querySelector('html');
  let scrollPosition = 0;

  useEffect(() => {
    $html.style.height = '100vh';
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
  }, [data]);



  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <img width={64} height={64} src={data.imgAlert} alt="Alert" />
        <p className={styles.title}>{data.title}</p>
        <p className={styles.description}>{data.description}</p>
        <Button
          id="AlertModal_PrimaryButton"
          className={styles.button}
          color="red"
          fullWidth
          href={data.urlRedirect}
          label={data.button}
          size="medium"
          variant="filled"
        />

      </div>
    </div>
  );
};


export default AlertModal;
