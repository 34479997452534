/* eslint-disable */
import React, { useState} from 'react';
import Modal from '../../../../components/Modal'
import styles from './styles.module.css';

const Reserve = () => {

  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal(true);
  }
  const handleClose = () => {
    setModal(false);
  }

  return (
    <section className={styles.reserve}>
      <h2 className={styles.title}>¡No te preocupes! Tu cita es <span className={styles.titleBold}>gratis</span></h2>
      <p className={styles.text}>Reserva tu espacio sin compromiso, puedes cancelar o reagendar cuando quieras.<span className={styles.cancel} onClick={handleModal}>Ver política de cancelación</span></p>
      <p className={styles.textSecondary}>Debido a la alta demanda de citas, es necesario ingresar tu tarjeta para completar la reserva. Solo en caso de que no asistas o si cancelas/reagendas con menos de 24 horas de antelación, haremos un cargo de $290 MXN a tu tarjeta.</p>
      <Modal isOpen={modal} isClose={handleClose} />
    </section>
  )
};

export default Reserve;


