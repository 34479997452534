/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@mymoons/ui-library';
import creditcardutils from 'creditcardutils';
import styles from './styles.module.css';
import icon_error from '../../../../assets/images/error.png'
import { getTable, getTableDB, sendCustomer } from '../../../../utils/Middleware'
import { PageLoader } from '@mymoons/ui-library'
import AlertModal from '../AlertModal'

const Checkout = ({ getPatient }) => {
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [pageloader, setPageloader] = useState(false)

  const [cardCvc, setCardCvc] = useState('');
  const [errorMessageStripe, setErrorMessageStripe] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const url = location.href.split('/')[3];
  const publicKey = url.split('?')[0];

  const [typeCard, setTypeCard] = useState('');
  const [loader, setLoader] = useState(false);
  const [orderedType, setOrderedType] = useState(false);

  useEffect(() => {
    window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY);
  }, []);

  useEffect(  () => {
    setTimeout(() => {
      setOrderedType(true);
    }, 600000);
  },[])


  const getTypeCard = (e) => {
    const { value } = e.target;
    if (value.length > 2) {
      const type = creditcardutils.parseCardType(value);
      setTypeCard(type);
    }

    let values = e.target.value;
    values = values.replace(/\s/g, '');

    let field = e.target;
    let position = field.selectionEnd;
    let length = field.value.length;

    field.value = field.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();

    field.selectionEnd = position +=
      field.value.charAt(position - 1) === ' ' &&
      field.value.charAt(length - 1) === ' '
        ? 1
        : 0;

    if (values.length === 18) {
      setCardNumber(field.value);
    } else {
      setCardNumber(field.value);
    }
  };

  const success =   (customerId) => {
    sendCustomer("MX-card-holder-sucess-info", customerId,"Card_required",true);

    
    const publicKey = location.pathname.split('/')[1];
    const redirectUrl = `${process.env.REACT_APP_USER_DASHBOARD_URL}?customerId=${customerId}&token=${publicKey}54${publicKey}`;

    const urlEncoded = encodeURIComponent(redirectUrl);

     setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_APPOINTMENT_PLATFORM}/${publicKey}/?Journey_Variant=card_holder&redirectUrl=${urlEncoded}&transactionId=${customerId}`
    },100) 
   
  }




  const handle_error = () => {
    setErrorMessageStripe(true);
    setPageloader(false)
    setLoader(false);
  }


  const onSubmit = async (data) => {
    const cardNumber = data.number.replace(/\s/g, '');
    setErrorMessageStripe(false)
    setLoader(true)
    setPageloader(true)

    const urlParams = new URLSearchParams(window.location.search);
    const customerID = urlParams.get('customerId');

    const cardInfo = {
      name: data.name,
      number: cardNumber,
      cvc: data.securityCode,
      exp_month: data.cardExpirationDate.split('/')[0],
      exp_year: data.cardExpirationDate.split('/')[1],
    };

    const stripeData = {
      ...cardInfo,
      address_country: 'México',
    };
   

  

    await window.Stripe.createToken(stripeData, async (result, response) => {
      if (result === 200) {

        const send_card_holder_temporal = await getTable(`${process.env.REACT_APP_CLASS_CARDHOLDER}/${customerID}`,{
          token: response.id,
          appointmentId: getPatient.Appointment_Id,
          last4 : cardNumber.substring(cardNumber.length, 12),
          cardType : typeCard,
          customerId: customerID
        });
        
        if(!send_card_holder_temporal.customerId){
          console.log('Error')
          handle_error()
        }else{
          success(send_card_holder_temporal.customerId);
        }

      }else{
        handle_error()
      }  
    });
    
  }


  return (
    <div className={styles.checkout_container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.checkout}>
          <div className={styles.card_container}>
            <p className={styles.card}>Tarjeta de crédito o débito</p>
            <div>
              <img src={`${process.env.REACT_APP_ASSETS_URL}/images/card_visa.svg`} alt="visa" className={styles.card_icon} />
              <img src={`${process.env.REACT_APP_ASSETS_URL}/images/mastercard.svg`} alt="mastercard" className={styles.card_icon} />
              <img src={`${process.env.REACT_APP_ASSETS_URL}/images/amex.svg`} alt="amex" className={styles.card_icon} />
            </div>
          </div>
          <label htmlFor="name" className={styles.name_card}>
            Nombre del tarjetahabiente
            <input type="text" id="name" 
              className={`${styles.input} ${styles.input_name}`} 
              {...register('name', { required: true, maxLength: 30} )} 
              placeholder="Nombre y apellidos" 
            />
            { errors.name?.type === 'required' && 
              <div className={styles.error_container}>
                <img className={styles.error_icon} src={icon_error} alt="error" />
                <p className={styles.error_message}>Ingresa el nombre que aparece en tu tarjeta</p> 
              </div>
            }
          </label>
          <p className={styles.name_card}>Información de tu tarjeta</p>
          <div className={styles.input_number_container}>
            {typeCard === 'visa' ? <img src={`${process.env.REACT_APP_ASSETS_URL}/images/card_visa.svg`} alt="visa" className={styles.image_type_card} /> : null}
            {typeCard === 'mastercard' ? <img src={`${process.env.REACT_APP_ASSETS_URL}/images/mastercard.svg`} alt="mastercard" className={styles.image_type_card} /> : null}
            {typeCard === 'amex' ? <img src={`${process.env.REACT_APP_ASSETS_URL}/images/amex.svg`} alt="amex" className={styles.image_type_card} /> : null}
            <input 
              type="text" 
              id="card" 
              {...register('number', { required: true, pattern: {
                value: /^[0-9 ]+$/i,
                message: "El formato de la tarjeta no es el correcto"
              }})} 
              className={`${styles.input} ${styles.input_card}`} 
              onChange={getTypeCard} 
              placeholder="4242 4242 4242 4242" 
              maxLength="19"
              value={cardNumber}
              />
          </div>
          <div className={styles.month_cvc_container}>
            <input 
              type="text" 
              {...register('cardExpirationDate', { required: true, maxLength: 5,  pattern: {
                value: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
                message: "El formato de la fecha no es el correcto"
              }})} 
              className={`${styles.input} ${styles.input_date}`} 
              placeholder="01/29" 
              maxLength="5"
              value={cardExpirationDate}
              onChange={(e) => {
                function cc_expires_format(string) {
                  return string
                    .replace(
                      /[^0-9]/g,
                      "" // To allow only numbers
                    )
                    .replace(
                      /^([2-9])$/g,
                      "0$1" // To handle 3 > 03
                    )
                    .replace(
                      /^(1{1})([3-9]{1})$/g,
                      "0$1/$2" // 13 > 01/3
                    )
                    .replace(
                      /^0{1,}/g,
                      "0" // To handle 00 > 0
                    )
                    .replace(
                      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
                      "$1/$2" // To handle 113 > 11/3
                    );
                }
                setCardExpirationDate(cc_expires_format(e.target.value));
              }}
              />
            <div className={styles.input_card_container}>
              <input
                type="text"
                {...register('securityCode', { required: true, maxLength: 4 })}
                className={`${styles.input} ${styles.input_cvc}`}
                placeholder="123"
                onChange={(e) => {
                  function cc_expires_format(string) {
                    return string.replace(
                      /[^0-9]/g,
                      '' // To allow only numbers
                    );
                  }
                  setCardCvc(cc_expires_format(e.target.value));
                }}
                value={cardCvc}
                maxLength={4}
              />
              <img src={`${process.env.REACT_APP_ASSETS_URL}/images/card_cvc.svg`} alt="amex" className={styles.image_cvc} />
            </div>
          </div>
          { errors.number?.message && 
            <div className={styles.error_container}>
              <img className={styles.error_icon} src={icon_error} alt="error" />
              <p className={styles.error_message}>El formato de la tarjeta no es el correcto</p>
            </div>
          }
          { errors.number?.type === 'required' && 
            <div className={styles.error_container}>
              <img className={styles.error_icon} src={icon_error} alt="error" />
              <p className={styles.error_message}>El número de la tarjeta es invalido</p>
            </div>
          }
          { errors.cardExpirationDate?.type === 'required' &&
            <div className={styles.error_container}>
              <img className={styles.error_icon} src={icon_error} alt="error" /> 
              <p className={styles.error_message}>Ingresa fecha de expiración de tu tarjeta</p>
            </div>
          }
          { errors.cardExpirationDate?.message && 
            <div className={styles.error_container}>
              <img className={styles.error_icon} src={icon_error} alt="error" />
              <p className={styles.error_message}>El formato de fecha no es el correcto</p>
            </div>
          }
          { errors.securityCode?.type === 'required' && 
            <div className={styles.error_container}>
              <img className={styles.error_icon} src={icon_error} alt="error" /> 
              <p className={styles.error_message}>Ingresa el código de seguridad</p>
            </div>
          }
          { errorMessageStripe && 
            <div className={styles.error_container}>
              <img className={styles.error_icon} src={icon_error} alt="error" /> 
              <p className={styles.error_message}>El método de pago es erróneo. Intentalo nuevamente.</p>
            </div>
          }
        </div>
        <div className={styles.submit_container}>
        <Button
            color="red"
            iconVariant="regular"
            isButton
            label="Reservar cita gratis"
            rel="noopener noreferrer"
            size="small"
            target="_blank"
            variant="filled"
            loading={loader}
            fullWidth

        />
        </div>
      </form>
      <div className={styles.no_card_text_container}>
        <a href={`${process.env.REACT_APP_CHECKOUT}/cita/${publicKey}?utm_source=card_holder`} rel="noreferrer" className={styles.no_card_text} target="_blank" id="old">
            ¿No tienes tarjeta para reservar? Paga un anticipo de $290 MXN
        </a>
      </div>
      {
        pageloader && <PageLoader />
      }

      {orderedType  && (
        <AlertModal publicKey={publicKey} />
      )}
      
    </div>
  );
};

export default Checkout;
