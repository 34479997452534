/* eslint-disable */
import { callApi, basicAuthHeader, callApiDirectly, callApiCustomer, basicAuthHeaderCardholder, basicAuthHeader_patient, basicAuthHeader_tracking } from './Request'

/**
 * Get Table
 * @param {String} url .
 * @param {String} table .
 * @param {String} order .
 * @param {String} item .
 * @param {Function} success .
 * @param {Function} error .
 * @returns {Object} Patient Object
 */
/* export const getTable = (url, items, success, error) => callApi(url, {
  headers: basicAuthHeader(),
  method: 'POST',
  body: JSON.stringify({
    ...items
  }),
}, success, error) */

export const getTableDB = (url, table, order, item) => callApiDirectly(url, {
  headers: basicAuthHeader_patient(),
  method: 'POST',
  body: JSON.stringify({
    table,
    columns: {
      ...item,
    },
    order
  }),
});


export const getTable = (url, item) => callApiDirectly(url, {
  headers: basicAuthHeaderCardholder(),
  method: 'POST',
  body: JSON.stringify({
   
      ...item,
    
  }),
});


export const sendCustomer = ( eventName, customerId, paymentStatus, customerio) => callApiCustomer( process.env.REACT_APP_CUSTOMER, {
  headers: basicAuthHeader_tracking(),
  method: 'POST',
  body: JSON.stringify({
    eventName,
    eventData: { 
            distinct_id: customerId,
            customerId,
            paymentStatus
    },
    sendToCustomerIO:customerio
  }),
});


