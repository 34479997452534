/* eslint-disable */
import React, { useEffect, useState} from 'react';
import Header from '../../components/Header';
import Reserve from './components/Reserve';
import Checkout from './components/Checkout';
import Footer from '../../components/Footer';
import { freshPaintPage } from '../../utils/tracking';
import { PageLoader } from '@mymoons/ui-library'
import '@mymoons/ui-library/dist/library.min.css';
import { ErrorPage } from '@mymoons/error-page/dist/errorPage';
import './styles.css';
import { getTableDB, sendCustomer } from '../../utils/Middleware'

const Home =  () => {
  //freshPaintPage('MX', 'checkout-card_holder', 'card-holder');

  const customerId = new URLSearchParams(window.location.search).get('customerId');
  const publicKey = location.pathname.split('/')[1];
  
  const [getPatient, setGetPatient] = useState(false);

  const handle = async () => {
    const patient =  await getTableDB(process.env.REACT_APP_MIDDLEWARE_ITEM, 'Patient', null, { PublicKey: publicKey });
    setGetPatient(patient.Patient)
  }

  useEffect(  () => {
    handle()
  },[])

 



  if (!customerId || !publicKey) {
    return <ErrorPage />;
  } 

  
  const render_app = () => {
    if(!getPatient){
      return <PageLoader />
    } else if(getPatient && getPatient.CustomerId === customerId){

      return <>
      <Header />
        <div className="main_container">
        <Reserve />
        <Checkout getPatient={getPatient} />
        <Footer />
      </div>
    </>
    } else {
      return <ErrorPage />;
    }
  }



  return (
    <div>
    {
      render_app()
    } 
    </div>
  );
};

export default Home;
