/* eslint-disable */
import React from 'react';
import  ReactDOM from 'react-dom';
import styles from './styles.module.css';
import modal_icon from '../../assets/images/Modal.png';

const Modal = ({isOpen, isClose}) => {


    if(!isOpen) return null;
    

    return ReactDOM.createPortal(
        <div className={styles.modal} onClick={isClose}>
            <div className={styles.modal_container}>
                <div className={styles.body_modal}>
                    <img src={modal_icon} alt="warning" className={styles.modal_icon} />
                    <p className={styles.modal_title}>Política de cancelación</p>
                    <p className={styles.modal_text}>Solo en caso de que no asistas o si cancelas/reagendas con menos de 24 horas de antelación, haremos un cargo de <span className={styles.modal_textBold}>$290 MXN</span> a tu tarjeta. Esto lo hacemos para asegurar tu reserva debido a la alta demanda de citas que tenemos actualmente.</p>
                    <button className={styles.modal_btn} onClick={isClose}>Continuar</button>
                </div>  
            </div>
        </div>,
        document.getElementById('portal')
    );
}

export default Modal;