/* eslint-disable */
import React from 'react';
import styles from './styles.module.css';

const Footer = () => (
    <div className={styles.footer}>
        <div className={styles.terms_container}>
            <p className={styles.terms}>© 2022 Moons</p>
            <a className={styles.terms} href="https://legal.mymoons.mx/terminos-y-condiciones?_gl=1*dux8pa*_ga*MTkzODk5Mjg5Ni4xNjU3ODM2NDEy*_ga_R2GSHCWSHT*MTY1OTA1Nzg4MC4xOC4xLjE2NTkwNTgwMjUuNDk." rel="noreferrer" target="_blank">Términos y condiciones</a>
            <a className={styles.terms} href="https://legal.mymoons.mx/aviso-de-privacidad?_gl=1*15nqq0v*_ga*MTkzODk5Mjg5Ni4xNjU3ODM2NDEy*_ga_R2GSHCWSHT*MTY1OTA1Nzg4MC4xOC4xLjE2NTkwNTgwMzEuNDM." rel="noreferrer" target="_blank">Aviso de privacidad</a>
        </div>
        <div className={styles.social_container}>
            <div className={styles.social}>
                <a target="_blank" rel="noreferrer" href="https://web.facebook.com/welovemoons">
                    <img src="https://mymoons.mx/v5/static/media/facebook.518b442c.svg" width="28px" height="28px" alt="Facebook" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://twitter.com/welovemoons">
                    <img src="https://mymoons.mx/v5/static/media/twitter.246a1c89.svg" width="28px" height="28px" alt="Twitter" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/welovemoons/">
                    <img src="https://mymoons.mx/v5/static/media/instagram.ec2493e2.svg" width="28px" height="28px" alt="Instagram" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.youtube.com/welovemoons">
                    <img src="https://mymoons.mx/v5/static/media/youtube.0eb670ab.svg" width="28px" height="28px" alt="YouTube" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/alineadores/" >
                    <img src="https://mymoons.mx/v5/static/media/linkedin.eaa2dcde.svg" width="28px" height="28px" alt="LinkedIn" />
                </a>
            </div>
        </div>
        <div className={styles.social_container}>
            <div className={styles.social}>
                <img src={`${process.env.REACT_APP_ASSETS_URL}/images/stripe@1x.webp`} alt="stripe" />
                <img src={`${process.env.REACT_APP_ASSETS_URL}/images/mercado@1x.webp`} alt="mercado pago" />
                <img src={`${process.env.REACT_APP_ASSETS_URL}/images/atrato@1x.webp`} alt="atrato" />
            </div>
        </div>
    </div>
);

export default Footer;